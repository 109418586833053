//私が！！！！作りました!!!!!!!!!YaHHHHHH
import React, { useState, useEffect, lazy } from 'react';
import ReactPlayer from 'react-player';
import './Top.css';
import Menu from '../Menu/Menu';

import Header from './Header';
import LazyPreloadImg from '../../Faster/Image';
const CDC = lazy(() => import('../../CDC/cdc'));

const Top = () => {
    const [showDiv, setShowDiv] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(true);
    if(showDiv)document.body.style.overflow = 'hidden';
    if(!showDiv)document.body.style.overflow = 'scroll';
    const deleteVideo = () => {
        setShowDiv(false);
        setIsPlaying(false);
    };
    const [IsntScroll, setIsntScroll] = useState<boolean>(false);
    const ScrollHandler = () => {
        setIsntScroll(true);
    }
    useEffect(() => {
        const hasVisited = sessionStorage.getItem('hasVisited');
        if (!hasVisited) {
            setShowDiv(true);
            sessionStorage.setItem('hasVisited', 'true');
        }
        else {
            setIsPlaying(false);
        }
        window.addEventListener('scroll',ScrollHandler);
    }, []);

    return (
        <>
        <div className={"top-video " + (!showDiv? "nainoda" : '')}>
            <ReactPlayer url={"./images/topvideo.mp4"} playing={true} muted={true} onEnded={deleteVideo} width="100vw" height="100vh" pip={false} playsinline={true} loop={false}
            config={{file: {attributes: {disablePictureInPicture: true, controlsList: 'nodownload', },},}}></ReactPlayer>
        </div>
        <div className={'black ' + (showDiv ? "aru" : "nai")}></div>
        {!isPlaying && (<div className='root' >
            <video className='background' autoPlay muted loop playsInline src='./images/HP.mp4' preload='auto' disablePictureInPicture ></video>
            <Menu />
            <Header />
            <div className='top'>
                <img src='./images/toptitle.webp' alt='「REBOOT」ロゴ画像' className='title' />
                <div className='undertitle'>
                    <div className='undertitle-when'>
                        <div className='undertitle-date'>11.1</div>
                        <div className='undertitle-day'>金</div>
                        <img src='./images/undertitle-arrow.webp' alt='右向きの矢印の画像。筑駒文化祭2024は11月1日から11月3日までの3日間開催中です。' className='undertitle-arrow'/>
                        <div className='undertitle-date'>11.3</div>
                        <div className='undertitle-day'>日</div>
                    </div>
                    <div className='undertitle-what'>終幕</div>
                </div>
                <div className='EndMessage'>
                    <div className='EndMessage-sentence'>2024筑駒文化祭 REBOOT は終了いたしました。</div>
                    <div className='EndMessage-sentence'>来場者は 3 日間で延べ 14405 人を記録しました。ご来場ありがとうございました。</div>
                    <div className='EndMessage-sentence'>2025筑駒文化祭への皆様のお越しを心よりお待ちしております。</div>
                </div>
            </div>
            
            <div className='footer'>
                <div className='foot-detail'>
                    <img src='./images/foot-title.webp' alt='「筑駒文化祭 REBOOT」筑駒文化祭 2024 のロゴ画像。' width={'200px'} height={'auto'} className='footer-title'></img>
                    <div className='address'>
                        筑波大学附属駒場中学校・高等学校<br />
                        〒154-0001<br />
                        東京都世田谷区池尻4-7-1
                    </div>
                    <div className='copy-right'>
                        © 73rd Tsukukoma Bunkasai “REBOOT”, Created by 73rd Tsukukoma Bunkasai 
                        Executive Commitee Junior and Senior High School at Komaba, Tsukuba University 
                        4-7-1 Ikejiri, Setagaya-ku, Tokyo 154-0001 Japan
                        </div>
                </div>
            </div>
        </div>
        )}
        </>
    );
};

export default Top;