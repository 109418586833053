import React, { useState, useEffect, lazy } from 'react';
import { useLocation } from 'react-router-dom';

import './Menu.css'
const Menucontents = lazy(() => import('./Menucontents'));

const Menu :React.FC = () => {
    const now = useLocation();
    const [isMOpen, setIsMOpen] = useState<boolean>(false);
    const handler = () => {
        setIsMOpen(!isMOpen);
    }
    const [isTop, setIsTop] = useState<boolean>((now.pathname==="/" ? true : false));
    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          if (now.pathname==="/"&&scrollPosition < 80) {
            setIsTop(true);
          } else {
            setIsTop(false);
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <div className='root'>
            <div className='menu'>
                <img src='./images/2024_menuicon.webp' className={'menu-icon ' + (isTop ? 'invisible' : 'visible')} alt='メニューアイコン画像。クリックでページ間を遷移できます。' onClick={handler} />
                <Menucontents isOpen={isMOpen} setIsOpen={handler} />
            </div>
        </div>
    );
};

export default Menu;

