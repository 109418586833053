import React, { useState, lazy } from "react";
import { Link } from "react-router-dom";
import './Header.css'
const Menucontents = lazy(() => import("../Menu/Menucontents"));

const Header = () => {
    const [isHOpen, setIsHOpen] = useState<boolean>(false);
    const Hhandler = () => {
        setIsHOpen(!isHOpen);
    }
    return (
        <div className='header'>
            <img src='./images/headertitle.webp' alt='「筑駒文化祭」2024年度ロゴ' className='header-title'/>
            <div className='header-sns'>
                <Link to='https://www.twitter.com/tsukukomafes' className="header-noX-link">
                <img src='./images/twitter.webp' alt='Twitter のロゴ画像' className='header-noX'/>
                </Link>
                <Link to='https://www.instagram.com/tsukukomafes' className="header-insta-link">
                <img src='./images/instagram.png' alt='Instagram のロゴ画像' className='header-insta'/>
                </Link>
                <div className={'headericon ' + (isHOpen ? 'open ' : 'close ')}
                onClick={() => setIsHOpen(!isHOpen)}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <Menucontents isOpen={isHOpen} setIsOpen={Hhandler} />
        </div>
    );

}

export default Header;