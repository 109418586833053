import { useState, useEffect } from "react";

// Function make debounce for update width
const debounce = (f: Function, d: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            f(...args);
        }, d);
    }
}

const useWidth = (): number => {
    const [width, setWidth] = useState(window.innerWidth);
    
    useEffect(() => {
      const updateWidth = debounce(() => {
        const newWidth = window.innerWidth / window.devicePixelRatio;
        if (newWidth !== window.screen.width) {
          setWidth(window.innerWidth);
        }
      }, 10);
      
      window.addEventListener('resize', updateWidth);
      
      return () => {
        window.removeEventListener('resize', updateWidth);
      };
    }, []);
    
    return width;
  };

export default useWidth;